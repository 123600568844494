import React from 'react';
import '../../assets/css/FixedDiv.css'; // Opcional: Para estilos

const FixedDiv = () => {
  return (
    <div className="fixed-div">
      Estas en el <span class="text-bold">AMBIENTE DE PRUEBAS.</span> Si deseas ir al ambiente productivo haz <a href="http://recruiting.ioon.es/">click aquí</a>
    </div>
  );
};

export default FixedDiv;