import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {
  Container, Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, Button, Form, FormGroup, Spinner
} from 'reactstrap'
import {Link } from 'react-router-dom'
import { Send, Filter, Watch, Flag, UserPlus, UserX, UserCheck, File  } from 'react-feather';
import { postWithData, get } from '../../services/api'
import DataTable from 'react-data-table-component'
import CountUp from 'react-countup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { formatQuery } from 'react-querybuilder';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { defaultValidator, QueryBuilder } from 'react-querybuilder';
import { defaultOperators } from 'react-querybuilder';
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import 'react-querybuilder/dist/query-builder.css';
import '../../assets/css/builderstyle.css';
import '../../assets/css/tablestyle.css';
import '../../assets/css/dashboardstyle.css';
import loadingGifCard from '../../assets/images/loadingDashboard.gif';

const Default = (props) => {

  const [daytimes, setDayTimes] = useState()
  const today = new Date()
  const curHr = today.getHours()
  const curMi = today.getMinutes()
  const [meridiem, setMeridiem] = useState("AM")
  // eslint-disable-next-line
  const [date, setDate] = useState({ date: new Date() });
  // eslint-disable-next-line
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { register, handleSubmit, errors } = useForm();


  const [listAllPositions, setListAllPositions] = useState([]);
  const [countAllPositions, setCountAllPositions] = useState(0);
  const [listAllCandidates, setListAllCandidates] = useState([]);
  const [countAllCandidates, setCountAllCandidates] = useState(0);
  const [listCandidatesNew, setListCandidatesNew] = useState([]);
  const [countCandidatesNew, setCountCandidatesNew] = useState(0);
  const [perfilActive, setPerfilActive] = useState(localStorage.getItem('NameRole'));

  const [listCandidatesAp, setListCandidatesAp] = useState([]);
  const [countCandidatesAp, setCountCandidatesAp] = useState(0);

  const [listCandidatesEntrevistados, setListCandidatesEntrevistados] = useState([]);
  const [countCandidatesEntrevistados, setCountCandidatesEntrevistados] = useState(0);

  const [listCandidateCVEnviados, setListCandidateCVEnviados] = useState([]);
  const [countCandidateCVEnviados, setCountCandidateCVEnviados] = useState(0);

  const [listCandidatePendientesNeg, setListCandidatePendientesNeg] = useState([]);
  const [countCandidatePendientesNeg, setCountCandidatePendientesNeg] = useState(0);

  const [visibleModalListCandidate, setVisibleModalListCandidate] = useState(false);
  
  const [listCandidatePositionData, setListCandidatePositionData] = useState([]);

  const [listCandidatesReRRHH, setListCandidatesReRRHH] = useState([]);
  const [listCandidatesReClie, setListCandidatesReClie] = useState([]);
  const [listCandidatesReEntre, setListCandidatesReEntre] = useState([]);
  const [countCandidatesRe, setCountCandidatesRe] = useState(0);

  const [listCandidates, setListCandidates] = useState([]);
  const [listCandidatesPosition, setListCandidatesPosition] = useState([]);
  const [listPositions, setListPositions] = useState([]);
  const [columnName, setColumnName] = useState(null);
  const [columnRecruiterEmpresa, setColumnRecruiterEmpresa] = useState(null);
  const [titleModalDetail, setTitleModalDetail] = useState(null);

  const openModalDetailListVacante = () => setVisibleModalDetailListVacante(!visibleModalDetailListVacante);
  const openModalDetailList = () => setVisibleModalDetailList(!visibleModalDetailList);
  const openModalDetailListEntrevista = () => setVisibleModalDetailListEntrevista(!visibleModalDetailListEntrevista);

  const [visibleModalDetailList, setVisibleModalDetailList] = useState(null);
  const [visibleModalDetailListEntrevista, setVisibleModalDetailListEntrevista] = useState(null);
  const [visibleModalDetailListVacante, setVisibleModalDetailListVacante] = useState(null);
  const [listCandidateData, setListCandidateData] = useState([]);
  const [listPositionData, setListPositionData] = useState([]);
  const [listStatusData, setListStatusData] = useState([]);
  const [listUserData, setListUserData] = useState([]);
  const [listUserInactiveData, setListUserInactiveData] = useState([]);
  const [listTechData, setListTechData] = useState([]);
  const [listLangData, setListLangData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isLoadingEnv, setIsLoadingEnv] = useState(true);
  const [isLoadingEntr, setIsLoadingEntr] = useState(true);
  const [isLoadingApro, setIsLoadingApro] = useState(true);
  const [isLoadingNeg, setIsLoadingNeg] = useState(true);
  const [isLoadingNew, setIsLoadingNew] = useState(true);
  const [isLoadingRec, setIsLoadingRec] = useState(true);
  const [isLoadingBtnExcel, setIsLoadingBtnExcel] = useState(true);

  const [listEstadosVacantesData, setListEstadosVacantesData] = useState([]);
  const [listClientesData, setListClientesData] = useState([]);
  const [listPriorityData, setListPriorityData] = useState([]);

  const initialQuery = { combinator: 'and', rules: [] };
  const initialQueryVacantes = { combinator: 'and', rules: [] };
  const [query, setQuery] = useState(initialQuery);
  const [queryVacante, setQueryVacante] = useState(initialQueryVacantes);

  useEffect(() => {

    if (curHr < 12) {
      setDayTimes('Good Morning')
    } else if (curHr < 18) {
      setDayTimes('Good Afternoon')
    } else {
      setDayTimes('Good Evening')
    }

    if (curHr >= 12) {
      setMeridiem('PM')
    } else {
      setMeridiem('AM')
    }

    // eslint-disable-next-line
    //getCountCandidates();
    getCountPositions();

    getCountCandidatesNew();
    getCountCandidatesEnv();
    getCountCandidatesNeg();
    getCountCandidatesEntr();
    getCountCandidatesRec();
    getCountCandidatesApro();
    

    getListPosition();
    getListStatus();
    getListTech();
    getListLang();
    getListUser();
    getListUserInactive();

    getListStatusVacantes();
    getListClients();

    setListPriorityData([
      {name: 'Muy Alta', label: 'Muy Alta' },
      {name: 'Alta', label: 'Alta'},
      {name: 'Media', label: 'Media'},
      {name: 'Baja', label: 'Baja'}
    ])

  }, [])

  const getListPosition = async () => {

    try {
      let dataList = [];

      get("api/position/list").then((result) => {
        if (result.data.length > 0) {

          result.data.forEach(element => {

            let item = {
              name: element.id,
              label: element.name
            }
            dataList.push(item);
          });
          setListPositionData(dataList);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getListStatus = async () => {

    try {
      let dataList = [];

      get("api/status/list").then((result) => {
        if (result.data.length > 0) {

          result.data.forEach(element => {

            let item = {
              name: element.id,
              label: element.name
            }
            dataList.push(item);
          });
          setListStatusData(dataList);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getListLang = async () => {

    try {
      let dataList = [];

      get("api/language/list").then((result) => {
        if (result.data.length > 0) {

          result.data.forEach(element => {

            let item = {
              name: element.id,
              label: element.name
            }
            dataList.push(item);
          });
          setListLangData(dataList);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getListTech = async () => {

    try {
      let dataList = [];

      get("api/technology/list").then((result) => {
        if (result.data.length > 0) {

          result.data.forEach(element => {

            let item = {
              name: element.id,
              label: element.name
            }
            dataList.push(item);
          });
          setListTechData(dataList);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getListUser = async () => {

    try {
      let dataList = [];

      get("api/user/list/active").then((result) => {
        if (result.data.length > 0) {

          result.data.forEach(element => {

            let item = {
              name: element.id,
              label: element.name_user
            }
            dataList.push(item);
          });
          setListUserData(dataList);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getListUserInactive = async () => {

    try {
      let dataList = [];

      get("api/user/list/inactive").then((result) => {
        if (result.data.length > 0) {

          result.data.forEach(element => {

            let item = {
              name: element.id,
              label: element.name_user
            }
            dataList.push(item);
          });
          setListUserInactiveData(dataList);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getListStatusVacantes = async () => {
    let dataRes = [];
    try {
        get("api/position/state/list").then((result) => {
            if (result.success == true) {
                result.data.forEach(element => {
                    dataRes.push({name: element.id, label: element.value});
                });
                setListEstadosVacantesData(dataRes);
            }
        });
    } catch (error) {
        console.log(error)
    }
}

  const getListClients = async () => {
    let dataRes = [];
    try {
        get("api/client/list").then((result) => {
            if (result.success == true) {
                result.data.forEach(element => {
                    dataRes.push({ name: element.id, label: element.name })
                });
                setListClientesData(dataRes)
            }
        });
    } catch (error) {
        console.log(error)
    }
}

const getExcelPositionsCandidatesData = async () => {
  try {
      get("api/export/excel/positions/candidates").then((result) => {
        console.log(result);
        if (result.success == true) {
          setListCandidatePositionData(result.data);
          setIsLoadingBtnExcel(false)
        }
      });
  } catch (error) {
      console.log(error)
  }
}

const exportExcelPositionsCandidates = async () => {
  try {

      var candidateExcelData = listCandidatePositionData.map(element => {
        return {
          "Id Posición" : element.position_id, 
          "Vacante" : element.position_name, 
          "Id Cliente" : element.client_id , 
          "Cliente" : element.client_name, 
          "Responsbale" : element.responsable, 
          //state_position_id BIGINT, 
          "Estado" : element.state_position_name, 
          "Fecha de alta" : element.date_begin, 
          "Candidato" : element.name_candidate, 
          "Recruiter" : element.name_recruiter,
          "Fecha de envio" : element.date_send,
          "Fecha de última entrevista" : element.date_interview
        }
      })

      var worksheet = XLSX.utils.json_to_sheet(candidateExcelData);
      var workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, `rrhh_vacantes_candidatos_activos_${moment().format()}.xlsx`);

  } catch (error) {
      console.log(error)
  }
}

  const buscarCandidatos = () => {
    let queryWhereSql = formatQuery(query, 'sql');
    //let queryBase = "SELECT  DISTINCT candidates.*,  (SELECT GROUP_CONCAT(A.name SEPARATOR ', ') FROM technologies A INNER JOIN tech_candidate B ON A.id = B.technology_id WHERE B.candidate_id = candidates.id) AS tecnho,(SELECT GROUP_CONCAT(A.name SEPARATOR ', ') FROM positions A INNER JOIN position_candidate B ON A.id = B.position_id WHERE B.candidate_id = candidates.id) AS vacante FROM candidates LEFT JOIN tracking_candidates ON tracking_candidates.candidate_id = candidates.id LEFT JOIN lang_candidate ON lang_candidate.candidate_id = candidates.id LEFT JOIN tech_candidate ON tech_candidate.candidate_id = candidates.id LEFT JOIN users ON users.id = tracking_candidates.user_id LEFT JOIN technologies ON technologies.id = tech_candidate.technology_id LEFT JOIN position_candidate ON position_candidate.candidate_id = candidates.id LEFT JOIN positions ON positions.id = position_candidate.position_id WHERE "

    //let queryResult = queryBase + '' + queryWhereSql
    let data = {
      advanced: queryWhereSql
    }

    //console.log(queryWhereSql)
    //console.log(query)
    try {
      /*
      postWithData("api/dashboard/candidate/count/advanced", data).then((result) => {
        if (result.success == true) {
          dataCandidate(result)
        }
      });
      */
      setIsLoadingNew(true);
      setIsLoadingEntr(true);
      setIsLoadingEnv(true);
      setIsLoadingRec(true);
      setIsLoadingNeg(true);
      setIsLoadingApro(true);

      //Nuevos
      postWithData("api/dashboard/candidate/count/advanced/new", data).then((result) => {
        if (result.success == true) {
          dataCandidateNew(result)
        }
      });

      //Enviados
      postWithData("api/dashboard/candidate/count/advanced/env", data).then((result) => {
        if (result.success == true) {
          dataCandidateEnv(result)
        }
      });

      //Negociacion
      postWithData("api/dashboard/candidate/count/advanced/neg", data).then((result) => {
        if (result.success == true) {
          dataCandidateNeg(result)
        }
      });

      //Aprobads
      postWithData("api/dashboard/candidate/count/advanced/apro", data).then((result) => {
        if (result.success == true) {
          dataCandidateApro(result)
        }
      });

      //Rechazados
      postWithData("api/dashboard/candidate/count/advanced/rec", data).then((result) => {
        if (result.success == true) {
          dataCandidateRec(result)
        }
      });

      //Entrevistados
      postWithData("api/dashboard/candidate/count/advanced/entr", data).then((result) => {
        if (result.success == true) {
          dataCandidateEntre(result)
        }
      });

    } catch (error) {
      console.log(error)
    }
  };

  const buscarVacantes = () => {
    let queryWhereSql = formatQuery(queryVacante, 'sql');
    //let queryBase = "SELECT  DISTINCT candidates.*,  (SELECT GROUP_CONCAT(A.name SEPARATOR ', ') FROM technologies A INNER JOIN tech_candidate B ON A.id = B.technology_id WHERE B.candidate_id = candidates.id) AS tecnho,(SELECT GROUP_CONCAT(A.name SEPARATOR ', ') FROM positions A INNER JOIN position_candidate B ON A.id = B.position_id WHERE B.candidate_id = candidates.id) AS vacante FROM candidates LEFT JOIN tracking_candidates ON tracking_candidates.candidate_id = candidates.id LEFT JOIN lang_candidate ON lang_candidate.candidate_id = candidates.id LEFT JOIN tech_candidate ON tech_candidate.candidate_id = candidates.id LEFT JOIN users ON users.id = tracking_candidates.user_id LEFT JOIN technologies ON technologies.id = tech_candidate.technology_id LEFT JOIN position_candidate ON position_candidate.candidate_id = candidates.id LEFT JOIN positions ON positions.id = position_candidate.position_id WHERE "
    //let queryResult = queryBase + '' + queryWhereSql
    let data = {
      advanced: queryWhereSql
    }

    console.log(queryWhereSql)
    console.log(queryVacante)
    try {

      postWithData("api/dashboard/position/count/advanced", data).then((result) => {
        if (result.success == true) {
            //console.log(result)
           dataPosiciones(result)
         }
      });
    } catch (error) {
      console.log(error)
    }

  };

  const fieldsCandidatos = [
    
    {
      name: 'candidates.city',
      label: 'Ciudad',
      placeholder: 'Ingrese ciudad',
    },
    {
      name: 'candidates.salary',
      label: 'Salario',
    },
    {
      name: 'positions.name',
      label: 'Vacante',
      operators: defaultOperators.filter((op) => op.name === 'contains'),
    },
    {
      name: 'technologies.name',
      label: 'Tecnología',
      operators: defaultOperators.filter((op) => op.name === 'contains'),
    },
    {
      name: 'tracking_candidates.user_id',
      label: 'Reclutador',
      valueEditorType: 'select',
      values: listUserData
    },
    {
      name: 'tracking_candidates.user_ids',
      label: 'Reclutador Inactivo',
      valueEditorType: 'select',
      values: listUserInactiveData
    },
    {
      name: 'lang_candidate.lang_id',
      label: 'Idioma',
      valueEditorType: 'select',
      values: listLangData
    },
    {
      name: 'CAST(candidates.date_register AS DATE)',
      label: 'Fecha de Registro',
      inputType: 'date'
    },
    {
      name: 'CAST(tracking_candidates.date_inerview_1 AS DATE)',
      label: 'Fecha de Entrevista 1',
      inputType: 'date'
    },
    {
      name: 'CAST(tracking_candidates.date_inerview_2 AS DATE)',
      label: 'Fecha de Entrevista 2',
      inputType: 'date'
    },
    {
      name: 'CAST(tracking_candidates.date_inerview_3 AS DATE)',
      label: 'Fecha de Entrevista 3',
      inputType: 'date'
    },
    {
      name: 'CAST(tracking_candidates.date_inerview_4 AS DATE)',
      label: 'Fecha de Entrevista 4',
      inputType: 'date'
    },
    {
      name: 'CAST(tracking_candidates.date_inerview_5 AS DATE)',
      label: 'Fecha de Entrevista 5',
      inputType: 'date'
    },
    {
      name: 'CAST(position_candidate.incorporation_date AS DATE)',
      label: 'Fecha de Incorporación',
      inputType: 'date'
    }
    /*,{
      name: 'date_register_enviado',
      label: 'Fecha de CV Enviado',
      inputType: 'date'
    },
    {
      name: 'date_register_rechazado',
      label: 'Fecha de Rechazo',
      inputType: 'date'
    },
    {
      name: 'date_register_negociacion',
      label: 'Fecha de Negociación',
      inputType: 'date'
    },
    {
      name: 'date_register_aprobado',
      label: 'Fecha de Aprobado',
      inputType: 'date'
    }*/
  ];

  const fieldsVacante = [
    {
      name: 'positions.client_id',
      label: 'Cliente',
      valueEditorType: 'select',
      values: listClientesData
    },
    {
      name: 'positions.state_position_id',
      label: 'Estado',
      valueEditorType: 'select',
      values: listEstadosVacantesData
    },
    {
      name: 'CAST(positions.date_register AS DATE)',
      label: 'Fecha de Registro',
      inputType: 'date'
    },
    {
      name: 'positions.is_portal',
      label: 'Es portal?',
      valueEditorType: 'checkbox',
      operators: defaultOperators.filter((op) => op.name === '='),
      defaultValue: false,
    },
    {
      name: 'positions.priority',
      label: 'Prioridad',
      valueEditorType: 'select',
      values: listPriorityData
    },
  ];

  function dataCandidate(result) {
    let dataListAll = [];
    let dataListNew = [];
    let dataListApp = [];
    let dataListPendiente = [];
    let dataListEntrevistados = [];
    let dataListCVEnviados = [];
    let dataListRez1 = [];
    let dataListRez2 = [];
    let dataListRez3 = [];

    //CANDIDATOS
    /*
    setCountAllCandidates(result.data.total)
    result.data.listAllCandidate.forEach(element => {
      let statusNameShow = (element.number_interview != null) ? <span>{element.status_name}<br /></span> : <span>{element.status_name}</span>;
      //console.log("aja", element.cliente)
      let item = {
        "id": element.id,
        "name_full": <Link to={`/candidate/history-candidate/${element.id}`} style={{ color: '#000', fontWeight: 'bold'  }}>{element.name + ' ' + element.lastname}</Link>,
        "name_recruiter": element.name_recruiter,
        "lastname": element.lastname,
        "second_lastname": element.second_lastname,
        "status_id": element.status_id,
        "vacante": element.vacante,
        "cliente": element.cliente,
        //"date_interview": element.fecha_entrevista,
        "status": <p style={{ color: element.status_color }}>{statusNameShow}</p>
      }
      dataListAll.push(item);
    });
    setListAllCandidates(dataListAll)
    */

    /*
    setCountCandidatesNew(result.data.countCandidateNew)
    result.data.listCandidateNew.forEach(element => {
      let statusNameShow = (element.number_interview != null) ? <span>{element.status_name}<br /></span> : <span>{element.status_name}</span>;

      let item = {
        "id": element.id,
        "name_full": <Link to={`/candidate/history-candidate/${element.id}`} style={{ color: '#000', fontWeight: 'bold' }}>{element.name + ' ' + element.lastname }</Link>,
        "name_recruiter": element.name_recruiter,
        "lastname": element.lastname,
        "second_lastname": element.second_lastname,
        "status_id": element.status_id,
        "vacante": element.vacante,
        "cliente": element.cliente,
        //"date_interview": element.fecha_entrevista,
        "status": <p style={{ color: element.status_color }}>{statusNameShow}</p>
      }
      dataListNew.push(item);
    });
    setListCandidatesNew(dataListNew)
    */

    /*
    setCountCandidatesAp(result.data.countCandidateAp)
    result.data.listCandidateAp.forEach(element => {
      let statusNameShow = (element.number_interview != null) ? <span>{element.status_name}<br /></span> : <span>{element.status_name}</span>;

      let item = {
        "id": element.id,
        "name_full": <Link to={`/candidate/history-candidate/${element.id}`}  style={{ color: '#000', fontWeight: 'bold' }}>{element.name + ' ' + element.lastname}</Link>,
        "name_recruiter": element.name_recruiter,
        "lastname": element.lastname,
        "second_lastname": element.second_lastname,
        "status_id": element.status_id,
        "vacante": element.vacante,
        "cliente": element.cliente,
        //"date_interview": element.fecha_entrevista,
        "status": <p style={{ color: element.status_color }}>{statusNameShow}</p>
      }
      dataListApp.push(item);
    });
    setListCandidatesAp(dataListApp)
    */

    /*
    setCountCandidatesRe(result.data.countCandidateRe)

    //Rechazao RRHH
    result.data.listCandidateReRRHH.forEach(element => {
      let statusNameShow = (element.number_interview != null) ? <span>{element.status_name}<br /></span> : <span>{element.status_name}</span>;
      //console.log("r", element)
      let item = {
        "id": element.id,
        "name_full": <Link to={`/candidate/history-candidate/${element.id}`} style={{ color: '#000', fontWeight: 'bold' }}>{element.name + ' ' + element.lastname}</Link>,
        "name_recruiter": element.name_recruiter,
        "lastname": element.lastname,
        "second_lastname": element.second_lastname,
        "status_id": element.status_id,
        "vacante": element.vacante,
        "cliente": element.cliente,
        //"date_interview": element.fecha_entrevista,
        "status": <p style={{ color: element.status_color }}>{statusNameShow}</p>
      }
      console.log("r", item)
      dataListRez1.push(item);
    });
    setListCandidatesReRRHH(dataListRez1)

    //Rechazao Cliente
    result.data.listCandidateReCliente.forEach(element => {
      let statusNameShow = (element.number_interview != null) ? <span>{element.status_name}<br /></span> : <span>{element.status_name}</span>;

      let item = {
        "id": element.id,
        "name_full": <Link to={`/candidate/history-candidate/${element.id}`} style={{ color: '#000', fontWeight: 'bold' }}>{element.name + ' ' + element.lastname}</Link>,
        "name_recruiter": element.name_recruiter,
        "lastname": element.lastname,
        "second_lastname": element.second_lastname,
        "status_id": element.status_id,
        "vacante": element.vacante,
        "cliente": element.cliente,
        //"date_interview": element.fecha_entrevista,
        "status": <p style={{ color: element.status_color }}>{statusNameShow}</p>
      }

      dataListRez2.push(item);
    });
    setListCandidatesReClie(dataListRez2)

    //Rechazao Entrevista
    result.data.listCandidateReEntrevista.forEach(element => {
      let statusNameShow = (element.number_interview != null) ? <span>{element.status_name}<br /></span> : <span>{element.status_name}</span>;

      let item = {
        "id": element.id,
        "name_full": <Link to={`/candidate/history-candidate/${element.id}`} style={{ color: '#000', fontWeight: 'bold' }}>{element.name + ' ' + element.lastname}</Link>,
        "name_recruiter": element.name_recruiter,
        "lastname": element.lastname,
        "second_lastname": element.second_lastname,
        "status_id": element.status_id,
        "vacante": element.vacante,
        "cliente": element.cliente,
        //"date_interview": element.fecha_entrevista,
        "status": <p style={{ color: element.status_color }}>{statusNameShow}</p>
      }

      dataListRez3.push(item);
    });
    setListCandidatesReEntre(dataListRez3)
    */

    /*
    setCountCandidatesEntrevistados(result.data.countCandidateEntrevistados)
    result.data.listCandidateEntrevistados.forEach(element => {
      let statusNameShow = (element.number_interview != null) ? <span>{element.status_name}<br /></span> : <span>{element.status_name}</span>;

      let item = {
        "id": element.id,
        "name_full": <Link to={`/candidate/history-candidate/${element.id}`} style={{ color: '#000', fontWeight: 'bold' }}>{element.name + ' ' + element.lastname}</Link>,
        "name_recruiter": element.name_recruiter,
        "lastname": element.lastname,
        "second_lastname": element.second_lastname,
        "status_id": element.status_id,
        "vacante": element.vacante,
        "cliente": element.cliente,
        "date_interview": <p title={element.fecha_entrevista_full}>{element.fecha_entrevista}</p>,
        "status": <p style={{ color: element.status_color }}>{statusNameShow}</p>
      }

      dataListEntrevistados.push(item);

    });
    setListCandidatesEntrevistados(dataListEntrevistados);
    */

    /*
    setCountCandidatePendientesNeg(result.data.countCandidatePendientesNeg)
    result.data.listCandidatePendientesNeg.forEach(element => {
      let statusNameShow = (element.number_interview != null) ? <span>{element.status_name}<br /></span> : <span>{element.status_name}</span>;

      let item = {
        "id": element.id,
        "name_full": <Link to={`/candidate/history-candidate/${element.id}`} style={{ color: '#000', fontWeight: 'bold' }}>{element.name+ ' ' + element.lastname}</Link>,
        "name_recruiter": element.name_recruiter,
        "lastname": element.lastname,
        "second_lastname": element.second_lastname,
        "status_id": element.status_id,
        "vacante": element.vacante,
        "cliente": element.cliente,
        //"date_interview": element.fecha_entrevista,
        "status": <p style={{ color: element.status_color }}>{statusNameShow}</p>
      }

      dataListPendiente.push(item);
    });
    setListCandidatePendientesNeg(dataListPendiente);
    */

    /*
    setCountCandidateCVEnviados(result.data.countCandidateCVEnviados)
    result.data.listCandidateCVEnviados.forEach(element => {
      let statusNameShow = (element.number_interview != null) ? <span>{element.status_name}<br /></span> : <span>{element.status_name}</span>;

      let item = {
        "id": element.id,
        "name_full": <Link to={`/candidate/history-candidate/${element.id}`} style={{ color: '#000', fontWeight: 'bold' }}>{element.name + ' ' + element.lastname}</Link>,
        "name_recruiter": element.name_recruiter,
        "lastname": element.lastname,
        "second_lastname": element.second_lastname,
        "status_id": element.status_id,
        "vacante": element.vacante,
        "cliente": element.cliente,
        //"date_interview": element.fecha_entrevista,
        "status": <p style={{ color: element.status_color }}>{statusNameShow}</p>
      }

      dataListCVEnviados.push(item);

    });
    setListCandidateCVEnviados(dataListCVEnviados);
    */
  }

  function dataCandidateNew(result) {
    let dataListAll = [];
    let dataListNew = [];
    

    setCountCandidatesNew(result.data.countCandidateNew)
    result.data.listCandidateNew.forEach(element => {
      let statusNameShow = (element.number_interview != null) ? <span>{element.status_name}<br /></span> : <span>{element.status_name}</span>;

      let item = {
        "id": element.id,
        "name_full": <Link to={`/candidate/history-candidate/${element.id}`} style={{ color: '#000', fontWeight: 'bold' }}>{element.name + ' ' + element.lastname }</Link>,
        "name_recruiter": element.name_recruiter,
        "lastname": element.lastname,
        "second_lastname": element.second_lastname,
        "status_id": element.status_id,
        "vacante": element.vacante,
        "cliente": element.cliente,
        //"date_interview": element.fecha_entrevista,
        "status": <p style={{ color: element.status_color }}>{statusNameShow}</p>
      }
      dataListNew.push(item);
    });
    setListCandidatesNew(dataListNew)
    setIsLoadingNew(false);
  }

  function dataCandidateEnv(result) {

    let dataListCVEnviados = [];

    setCountCandidateCVEnviados(result.data.countCandidateCVEnviados)
    result.data.listCandidateCVEnviados.forEach(element => {
      let statusNameShow = (element.number_interview != null) ? <span>{element.status_name}<br /></span> : <span>{element.status_name}</span>;

      let item = {
        "id": element.id,
        "name_full": <Link to={`/candidate/history-candidate/${element.id}`} style={{ color: '#000', fontWeight: 'bold' }}>{element.name + ' ' + element.lastname}</Link>,
        "name_recruiter": element.name_recruiter,
        "lastname": element.lastname,
        "second_lastname": element.second_lastname,
        "status_id": element.status_id,
        "vacante": element.vacante,
        "cliente": element.cliente,
        //"date_interview": element.fecha_entrevista,
        "status": <p style={{ color: element.status_color }}>{statusNameShow}</p>
      }

      dataListCVEnviados.push(item);

    });
    setListCandidateCVEnviados(dataListCVEnviados);
    setIsLoadingEnv(false);
  }

  function dataCandidateNeg(result) {

    let dataListPendiente = [];

    setCountCandidatePendientesNeg(result.data.countCandidatePendientesNeg)
    result.data.listCandidatePendientesNeg.forEach(element => {
      let statusNameShow = (element.number_interview != null) ? <span>{element.status_name}<br /></span> : <span>{element.status_name}</span>;

      let item = {
        "id": element.id,
        "name_full": <Link to={`/candidate/history-candidate/${element.id}`} style={{ color: '#000', fontWeight: 'bold' }}>{element.name+ ' ' + element.lastname}</Link>,
        "name_recruiter": element.name_recruiter,
        "lastname": element.lastname,
        "second_lastname": element.second_lastname,
        "status_id": element.status_id,
        "vacante": element.vacante,
        "cliente": element.cliente,
        //"date_interview": element.fecha_entrevista,
        "status": <p style={{ color: element.status_color }}>{statusNameShow}</p>
      }

      dataListPendiente.push(item);
    });
    setListCandidatePendientesNeg(dataListPendiente);
    setIsLoadingNeg(false);
  }

  function dataCandidateEntre(result) {

    let dataListEntrevistados = [];

    setCountCandidatesEntrevistados(result.data.countCandidateEntrevistados)
    result.data.listCandidateEntrevistados.forEach(element => {
      let statusNameShow = (element.number_interview != null) ? <span>{element.status_name}<br /></span> : <span>{element.status_name}</span>;

      let item = {
        "id": element.id,
        "name_full": <Link to={`/candidate/history-candidate/${element.id}`} style={{ color: '#000', fontWeight: 'bold' }}>{element.name + ' ' + element.lastname}</Link>,
        "name_recruiter": element.name_recruiter,
        "lastname": element.lastname,
        "second_lastname": element.second_lastname,
        "status_id": element.status_id,
        "vacante": element.vacante,
        "cliente": element.cliente,
        "date_interview": <p title={element.fecha_entrevista_full}>{element.fecha_entrevista}</p>,
        "status": <p style={{ color: element.status_color }}>{statusNameShow}</p>
      }

      dataListEntrevistados.push(item);

    });
    setListCandidatesEntrevistados(dataListEntrevistados);
    setIsLoadingEntr(false);
  }

  function dataCandidateApro(result) {

    let dataListApp = [];

    setCountCandidatesAp(result.data.countCandidateAp)
    result.data.listCandidateAp.forEach(element => {
      let statusNameShow = (element.number_interview != null) ? <span>{element.status_name}<br /></span> : <span>{element.status_name}</span>;

      let item = {
        "id": element.id,
        "name_full": <Link to={`/candidate/history-candidate/${element.id}`}  style={{ color: '#000', fontWeight: 'bold' }}>{element.name + ' ' + element.lastname}</Link>,
        "name_recruiter": element.name_recruiter,
        "lastname": element.lastname,
        "second_lastname": element.second_lastname,
        "status_id": element.status_id,
        "vacante": element.vacante,
        "cliente": element.cliente,
        //"date_interview": element.fecha_entrevista,
        "status": <p style={{ color: element.status_color }}>{statusNameShow}</p>
      }
      dataListApp.push(item);
    });
    setListCandidatesAp(dataListApp)
    setIsLoadingApro(false);
  }

  function dataCandidateRec(result) {

    let dataListRez1 = [];
    let dataListRez2 = [];
    let dataListRez3 = [];

    setCountCandidatesRe(result.data.countCandidateRe)

    //Rechazao RRHH
    result.data.listCandidateReRRHH.forEach(element => {
      let statusNameShow = (element.number_interview != null) ? <span>{element.status_name}<br /></span> : <span>{element.status_name}</span>;
      //console.log("r", element)
      let item = {
        "id": element.id,
        "name_full": <Link to={`/candidate/history-candidate/${element.id}`} style={{ color: '#000', fontWeight: 'bold' }}>{element.name + ' ' + element.lastname}</Link>,
        "name_recruiter": element.name_recruiter,
        "lastname": element.lastname,
        "second_lastname": element.second_lastname,
        "status_id": element.status_id,
        "vacante": element.vacante,
        "cliente": element.cliente,
        //"date_interview": element.fecha_entrevista,
        "status": <p style={{ color: element.status_color }}>{statusNameShow}</p>
      }
      //console.log("r", item)
      dataListRez1.push(item);
    });
    setListCandidatesReRRHH(dataListRez1)

    //Rechazao Cliente
    result.data.listCandidateReCliente.forEach(element => {
      let statusNameShow = (element.number_interview != null) ? <span>{element.status_name}<br /></span> : <span>{element.status_name}</span>;

      let item = {
        "id": element.id,
        "name_full": <Link to={`/candidate/history-candidate/${element.id}`} style={{ color: '#000', fontWeight: 'bold' }}>{element.name + ' ' + element.lastname}</Link>,
        "name_recruiter": element.name_recruiter,
        "lastname": element.lastname,
        "second_lastname": element.second_lastname,
        "status_id": element.status_id,
        "vacante": element.vacante,
        "cliente": element.cliente,
        //"date_interview": element.fecha_entrevista,
        "status": <p style={{ color: element.status_color }}>{statusNameShow}</p>
      }

      dataListRez2.push(item);
    });
    setListCandidatesReClie(dataListRez2)

    //Rechazao Entrevista
    result.data.listCandidateReEntrevista.forEach(element => {
      let statusNameShow = (element.number_interview != null) ? <span>{element.status_name}<br /></span> : <span>{element.status_name}</span>;

      let item = {
        "id": element.id,
        "name_full": <Link to={`/candidate/history-candidate/${element.id}`} style={{ color: '#000', fontWeight: 'bold' }}>{element.name + ' ' + element.lastname}</Link>,
        "name_recruiter": element.name_recruiter,
        "lastname": element.lastname,
        "second_lastname": element.second_lastname,
        "status_id": element.status_id,
        "vacante": element.vacante,
        "cliente": element.cliente,
        //"date_interview": element.fecha_entrevista,
        "status": <p style={{ color: element.status_color }}>{statusNameShow}</p>
      }

      dataListRez3.push(item);
    });
    setListCandidatesReEntre(dataListRez3)
    setIsLoadingRec(false);
  }

  function dataPosiciones(result) {
    let dataList = [];

    //POSICIONES
    setCountAllPositions(result.data.total)
    result.data.list.forEach(element => {

      let item = {
        "id": element.id,
        "name": <Link to={`/position/edit/${element.id}`} style={{ color: '#000', fontWeight: 'bold'  }}>{element.name}</Link>,
        "name_client": element.name_client,
        "responsable": element.responsable,
        "count_candidates": element.count_candidates,
        "candidatos_vivos": <p className="d-flex justify-content-center align-items-center">
          {<Button style={{ padding: 0,}}  color='red' onClick={() => showDetailListCandiatePosition(element.list_vivos, 0)} > {element.candidatos_vivos} </Button>} </p>,
        "candidatos_rechazados": <p className="d-flex justify-content-center align-items-center">
          {<Button style={{ padding: 0,}}  color='red' onClick={() => showDetailListCandiatePosition(element.list_rechazados, 0)} > {element.candidatos_rechazados} </Button>} </p>,
        "date_register": element.date_register,
        "status": <p>{element.name_state}</p>
      }
      dataList.push(item);
    });
    setListAllPositions(dataList)

  }

  const getCountCandidates = async (data) => {
    /*try {
      postWithData("api/dashboard/candidate/count/advanced", data).then((result) => {
        dataCandidate(result)
        
      });
    } catch (error) {
      console.log(error)
    }*/
  }

  const getCountCandidatesNew = async (data) => {
    setIsLoadingNew(true);
    try {
      postWithData("api/dashboard/candidate/count/advanced/new", data).then((result) => {
        dataCandidateNew(result)

      });
    } catch (error) {
      console.log(error)
    }
  }

  const getCountCandidatesEnv = async (data) => {
    setIsLoadingEnv(true);
    try {
      postWithData("api/dashboard/candidate/count/advanced/env", data).then((result) => {
        dataCandidateEnv(result)
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getCountCandidatesNeg = async (data) => {
    setIsLoadingNeg(true);
    try {
      postWithData("api/dashboard/candidate/count/advanced/neg", data).then((result) => {
        dataCandidateNeg(result)
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getCountCandidatesApro = async (data) => {
    setIsLoadingApro(true);
    try {
      postWithData("api/dashboard/candidate/count/advanced/apro", data).then((result) => {
        dataCandidateApro(result)
      });
    } catch (error) {
      console.log(error)
    }
  }
  
  const getCountCandidatesRec = async (data) => {
    setIsLoadingRec(true);
    try {
      postWithData("api/dashboard/candidate/count/advanced/rec", data).then((result) => {
        dataCandidateRec(result)
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getCountCandidatesEntr = async (data) => {
    setIsLoadingEntr(true);
    try {
      postWithData("api/dashboard/candidate/count/advanced/entr", data).then((result) => {
        dataCandidateEntre(result)
      });
    } catch (error) {
      console.log(error)
    }
  }


  const disassociatePosition = async (id_candidate, id_position) => {
    
    try {
      let data = {
        id_position : id_position,
        id_candidate : id_candidate
      }
      console.log(data)
      
      postWithData("api/position/candidate/disassociate", data).then((result) => {

        //setVisibleModalDesasociarPosition(false);
        toast.success(`Se desvinculo esta vacante de forma satisfactoria.`);
        //window.location.href = window.location.href;     
               
      });
       
    } catch (error) {
      console.log(error)
    }
  }

  const showDetailListCandiatePosition = async (list, idPosition) => {
    
    setVisibleModalListCandidate(!visibleModalListCandidate);
    console.log(list, idPosition)
    
    let listCand = [];
    list.forEach(element => {

      let item = {
        "full_name_completo" : <Link to={`/candidate/history-candidate/${element.id}`} style={{ color: '#000', fontWeight: 'bold' }} target="_blank" rel="noopener noreferrer">{element.full_name_c}</Link>,
        "email" : element.email,
        "phone" : element.phone,
        "recruiter" : element.recruiter,
        "baja" : <Button style={{ padding: 0,}} color='red' onClick={() => disassociatePosition(element.id, idPosition)} > <i className= "icon-trash"></i> </Button>
      };

      listCand.push(item)
    });

    setListCandidatesPosition(listCand)
  }

  const getCountPositions = async (data) => {
    try {
      
      postWithData("api/dashboard/position/count/advanced", data).then((result) => {
        dataPosiciones(result)

      });
    } catch (error) {
      console.log(error)
    }
  }

  const showListDetail = async (list) => {

    setColumnName("Nombre")
    setColumnRecruiterEmpresa("Recruiter")

    switch (list) {
      case 'positions':
        getExcelPositionsCandidatesData()
        //setTitleModalDetail("Vacantes")
        //setColumnName("Vacante")
        //setColumnRecruiterEmpresa("Cliente")
        setListPositions(listAllPositions)
        setVisibleModalDetailListVacante(true)
        break;
      case 'new':
        setTitleModalDetail("Canditatos agregados recientemente")
        setListCandidates(listCandidatesNew)
        setVisibleModalDetailList(true);
        break;
      case 'app':
        setTitleModalDetail("Canditatos Aprobados")
        setListCandidates(listCandidatesAp)
        setVisibleModalDetailList(true);
        break;
      case 'negociacion':
        setTitleModalDetail("Canditatos en negociación")
        setListCandidates(listCandidatePendientesNeg)
        setVisibleModalDetailList(true);
        break;
      case 'entrevistados':
        setTitleModalDetail("Canditatos entrevistados")
        setListCandidates(listCandidatesEntrevistados)
        //setVisibleModalDetailList(true);
        setVisibleModalDetailListEntrevista(true);
        break;
      case 'enviados':
        setTitleModalDetail("Canditatos Enviados")
        setListCandidates(listCandidateCVEnviados)
        setVisibleModalDetailList(true);
        break;
      case 'rez':
        setTitleModalDetail("Canditatos Rechazados por Cliente y/o RRHH")
        //let totalList = {...listCandidatesReRRHH, ...listCandidatesReClie}
        let totalList = []
        listCandidatesReRRHH.forEach(element => {
          totalList.push(element);
        });
        listCandidatesReClie.forEach(element => {
          totalList.push(element);
        });
        listCandidatesReEntre.forEach(element => {
          totalList.push(element);
        });
        setListCandidates(totalList)
        setVisibleModalDetailList(true);
        break;
      default:
        break;
    }
    
  }

  const openModalListCandidate = (id, tipo) => {
    setVisibleModalListCandidate(!visibleModalListCandidate);
  };

  const tableColumns = [
    {
      name: columnName,
      selector: 'name_full',
      sortable: true,
      center: false,
    },
    {
      name: columnRecruiterEmpresa,
      selector: 'name_recruiter',
      sortable: true,
      center: false,
    },
    {
      name: 'Cliente',
      selector: 'cliente',
      sortable: true,
      center: false,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      center: false,
    }
  ]

  const tableColumnsEntrevista = [
    {
      name: columnName,
      selector: 'name_full',
      sortable: true,
      center: false,
    },
    {
      name: columnRecruiterEmpresa,
      selector: 'name_recruiter',
      sortable: true,
      center: false,
    },
    {
      name: 'Cliente',
      selector: 'cliente',
      sortable: true,
      center: false,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      center: false,
    },
    {
      name: 'Fecha de Entrevista',
      selector: 'date_interview',
      sortable: true,
      center: false,
    },
  ]

  const tableColumnsVacante = [
    {
      name: 'Vacante',
      selector: 'name',
      sortable: true,
      center: false,
    },
    {
      name: 'Cliente',
      selector: 'name_client',
      sortable: true,
      center: false,
    },
    {
      name: 'Responsable',
      selector: 'responsable',
      sortable: true,
      center: false,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      center: false,
      width: '180px'
    },
    {
      name: 'Fecha de Alta',
      selector: 'date_register',
      sortable: true,
      center: false,
      width: '180px'
    },
    {
      name: 'Candidatos Enviados ',
      selector: 'count_candidates',
      sortable: true,
      center: true,
      width: '120px'
    },
    {
      name: 'Candidatos Activos ',
      selector: 'candidatos_vivos',
      sortable: true,
      center: true,
      width: '120px'
    },
    {
      name: 'Candidatos Rechazados ',
      selector: 'candidatos_rechazados',
      sortable: true,
      center: true,
      width: '120px'
    }
  ]

  const tableColumnsComerical = [
    {
      name: columnName,
      selector: 'name_full',
      sortable: true,
      center: false,
    },
    {
      name: columnRecruiterEmpresa,
      selector: 'name_recruiter',
      sortable: true,
      center: false,
    },
    {
      name: 'Vacante',
      selector: 'vacante',
      sortable: true,
      center: false,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      center: false,
    },

  ]

  const tableColumnsCandidate = [
    {
      name: 'Nombre y Apellidos',
      selector: 'full_name_completo',
      sortable: true,
      center: false,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      center: false,
    },
    {
      name: 'Teléfono',
      selector: 'phone',
      sortable: true,
      center: false,
    },
    {
      name: 'Reclutador',
      selector: 'recruiter',
      sortable: true,
      center: false,
    },
    {
      name: 'Dar de baja',
      selector: 'baja',
      sortable: true,
      center: false,
    }
    
  ]

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Dashboard" />
      <Container fluid={true}>
          <div>
            <h3>Candidatos</h3>
            <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
              <QueryBuilder
                fields={fieldsCandidatos}
                query={query}
                onQueryChange={(q) => setQuery(q)}
                showCombinatorsBetweenRules
                validator={defaultValidator}
                controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
              />
            </QueryBuilderDnD>
            <br />
            <Button className="btn-search" color="primary" onClick={buscarCandidatos}>{"Actualizar Candidatos"}</Button>
          </div>

        <Row className="second-chart-list third-news-update">
          <Col sm="6" xl="3" lg="6">
            <Card 
            className={`o-hidden background-default ${
              (countCandidatesNew >= 23) ? 'background-semaforo-excelente ' : ''}${(countCandidatesNew >= 16 && countCandidatesNew <= 22) ? 'background-semaforo-bueno ' : ''}${(countCandidatesNew <= 16) ? 'background-semaforo-malo ' : ''}`
                }
            
              onClick={() => showListDetail("new")}>
              <CardBody className=" b-r-4 card-body">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center"><UserPlus /></div>
                  <div className="media-body"><span className="m-0">{"Nuevos"}</span>
                    <h4 className="mb-0 counter">
                    {isLoadingNew ? (
                        <div className="loader"><img src={loadingGifCard} alt="Loading..." width={25} /></div>
                        ) : (
                      <CountUp end={countCandidatesNew} />
                      )}
                      </h4><UserPlus className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="6" xl="3" lg="6">
            <Card 
            className={`o-hidden background-default ${
              (countCandidateCVEnviados >= 25) ? 'background-semaforo-excelente ' : ''}${(countCandidateCVEnviados >= 20 && countCandidateCVEnviados <= 24) ? 'background-semaforo-bueno ' : ''}${(countCandidateCVEnviados <= 20) ? 'background-semaforo-malo ' : ''}`
                }
            
              onClick={() => showListDetail("enviados")}>
              <div className=" b-r-4 card-body">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center"><Send /></div>
                  <div className="media-body"><span className="m-0">{"CV Enviados"}</span>
                    <h4 className="mb-0 counter">
                    {isLoadingEnv ? (
                      <div className="loader"><img src={loadingGifCard} alt="Loading..." width={25} /></div>
                      ) : (
                        <CountUp end={countCandidateCVEnviados} />
                      )}
                    </h4><Send className="icon-bg" />
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col sm="6" xl="3" lg="6">
            <Card 
            className={`o-hidden background-default ${
              (countCandidatesEntrevistados >= 25) ? 'background-semaforo-excelente ' : ''}${(countCandidatesEntrevistados >= 11 && countCandidatesEntrevistados <= 24) ? 'background-semaforo-bueno ' : ''}${(countCandidatesEntrevistados <= 10) ? 'background-semaforo-malo ' : ''}`
                }
            
              onClick={() => showListDetail("entrevistados")}>
              <div className="b-r-4 card-body">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center"><Filter /></div>
                  <div className="media-body"><span className="m-0">{"Entrevistados"}</span>
                    <h4 className="mb-0 counter">
                      {isLoadingEntr ? (
                        <div className="loader"><img src={loadingGifCard} alt="Loading..." width={25} /></div>
                        ) : (
                          <CountUp end={countCandidatesEntrevistados} />
                        )}  
                      </h4><Filter className="icon-bg" />
                  </div>
                </div>
              </div>
            </Card>
          </Col>

          <Col sm="6" xl="3" lg="6">
            <Card 
            className={`o-hidden background-default ${
              (countCandidatesRe >= 0 && countCandidatesRe <= 4) ? 'background-semaforo-malo ' : ''}${(countCandidatesRe >= 5 && countCandidatesRe <= 10) ? 'background-semaforo-bueno '  : ''}${(countCandidatesRe >= 11) ? 'background-semaforo-malo ' : ''}`
                }
            
              onClick={() => showListDetail("rez")}>
              <div className=" b-r-4 card-body ">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center"><UserX /></div>
                  <div className="media-body"><span className="m-0">{"Rechazados"}</span>
                    <h4 className="mb-0 counter">
                      {isLoadingRec ? (
                        <div className="loader"><img src={loadingGifCard} alt="Loading..." width={25} /></div>
                        ) : (
                        <CountUp end={countCandidatesRe} />
                      )} 
                    </h4><UserX className="icon-bg" />
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="second-chart-list third-news-update">
          <Col sm="6" xl="3" lg="6">
            <Card 
            className={`o-hidden background-default ${
              (countCandidatePendientesNeg >= 2) ? 'background-semaforo-excelente ' : ''}${(countCandidatePendientesNeg == 1) ? 'background-semaforo-bueno ' : ''}${(countCandidatePendientesNeg == 0) ? 'background-semaforo-malo ' : ''}`
                }
            
              onClick={() => showListDetail("negociacion")}>
              <CardBody className=" b-r-4 card-body">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center"><Watch /></div>
                  <div className="media-body"><span className="m-0">{"Negociación"}</span>
                    <h4 className="mb-0 counter">
                      {isLoadingNeg ? (
                        <div className="loader"><img src={loadingGifCard} alt="Loading..." width={25} /></div>
                        ) : (
                        <CountUp end={countCandidatePendientesNeg} />
                      )}
                      </h4><Watch className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="6" xl="3" lg="6">
            <Card 
            className={`o-hidden background-default ${
              (countCandidatesAp >= 4) ? 'background-semaforo-excelente ' : ''}${(countCandidatesAp == 4) ? 'background-semaforo-bueno ' : ''}${(countCandidatesAp <= 4) ? 'background-semaforo-malo ' : ''}`
                }
            
              onClick={() => showListDetail("app")}>
              <div className="b-r-4 card-body">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center"><UserCheck /></div>
                  <div className="media-body"><span className="m-0">{"Aprobados"}</span>
                    <h4 className="mb-0 counter">
                      {isLoadingApro ? (
                        <div className="loader"><img src={loadingGifCard} alt="Loading..." width={25} /></div>
                        ) : (
                          <CountUp end={countCandidatesAp} />
                        )}
                      </h4><UserCheck className="icon-bg" />
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          
        </Row>
        <div>
          <h3>Vacantes</h3>
            <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
              <QueryBuilder
                fields={fieldsVacante}
                query={queryVacante}
                onQueryChange={(q) => setQueryVacante(q)}
                showCombinatorsBetweenRules
                validator={defaultValidator}
                controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
              />
            </QueryBuilderDnD>
            <br />
            <Button className="btn-search" color="primary" onClick={buscarVacantes}>{"Actualizar Vacantes"}</Button>
          </div>
          <Row className="second-chart-list third-news-update">
            <Col sm="6" xl="3" lg="6">
              <Card className="o-hidden" onClick={() => showListDetail("positions")}>
                <div className=" b-r-4 card-body" style={{ background: "linear-gradient(to right, #8ad7e6 0%, #0099cc 100%)", color: "#FFF", cursor: "pointer" }}>
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center"><Flag /></div>
                    <div className="media-body"><span className="m-0">{"Vacantes"}</span>
                      <h4 className="mb-0 counter"><CountUp end={countAllPositions} /></h4><Flag className="icon-bg" />
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>

        <Modal size="lg" dialogClassName="custom-modal" isOpen={visibleModalDetailList} toggle={openModalDetailList}>
          <ModalHeader toggle={openModalDetailList}>
            {titleModalDetail}
          </ModalHeader>
          <ModalBody>
            <DataTable
              data={listCandidates}
              //columns={tableColumns}
              columns={(perfilActive == 'Comercial')? tableColumnsComerical : tableColumns}
              striped={true}
              center={true}
              persistTableHead
            />
          </ModalBody>
        </Modal>

        <Modal size="lg" dialogClassName="custom-modal" isOpen={visibleModalDetailListEntrevista} toggle={openModalDetailListEntrevista}>
          <ModalHeader toggle={openModalDetailListEntrevista}>
            {titleModalDetail}
          </ModalHeader>
          <ModalBody>
            <DataTable
              data={listCandidates}
              //columns={tableColumns}
              columns={(perfilActive == 'Comercial')? tableColumnsComerical : tableColumnsEntrevista}
              striped={true}
              center={true}
              persistTableHead
            />
          </ModalBody>
        </Modal>
        

        <Modal size="lg" dialogClassName="custom-modal" isOpen={visibleModalDetailListVacante} toggle={openModalDetailListVacante}>
          <ModalHeader toggle={openModalDetailListVacante}>
            {'Vacantes'}
          </ModalHeader>
          <ModalBody>
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
              {isLoadingBtnExcel ? (
                <Spinner size="sm" /> // Muestra el loader si está en modo de carga
                ) : (
                <>
                  <Button color="success" onClick={exportExcelPositionsCandidates}>
                    <i className="fa fa-file-excel-o"></i>&nbsp;
                      Exportar
                  </Button>
                </>
              )}
            </div>
            <DataTable
              data={listPositions}
              //columns={tableColumns}
              columns={tableColumnsVacante}
              striped={true}
              center={true}
              persistTableHead
            />
          </ModalBody>
        </Modal>

        <Modal size="lg" dialogClassName="custom-modal"  isOpen={visibleModalListCandidate} toggle={openModalListCandidate}>
          <ModalBody>
            <DataTable
              data={listCandidatesPosition}
              columns={tableColumnsCandidate}
              striped={true}
              center={true}
              persistTableHead
            />
          </ModalBody>
        </Modal>
      </Container>
    </Fragment>
  );
}

export default Default;
